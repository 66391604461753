import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CabinetsCard from '@/views/AuthPage/CabinetsCard.vue';
let ChangeCabinets = class ChangeCabinets extends Vue {
    constructor() {
        super(...arguments);
        this.showAllText = false;
    }
    get cabinetsData() {
        return this.$store.state.auth.cabinets;
    }
};
ChangeCabinets = __decorate([
    Component({
        components: {
            CabinetsCard,
        },
    })
], ChangeCabinets);
export default ChangeCabinets;
