import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let CabinetsCard = class CabinetsCard extends Vue {
    get currentUserId() {
        return this.$store.state.personalArea.patient.user_id;
    }
    checkCabinet(id) {
        if (id !== this.currentUserId) {
            this.$store.dispatch('auth/changeCabinet', id);
        }
    }
};
__decorate([
    Prop()
], CabinetsCard.prototype, "cabinet", void 0);
CabinetsCard = __decorate([
    Component({})
], CabinetsCard);
export default CabinetsCard;
